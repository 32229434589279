<template>
  <div id="operator-detail-page">
    <vs-popup classContent="popup-example" :title="`${$t('change')} ${$t('password')}`" :active.sync="showModal">
      <template>  
        <div class="grid grid-cols-2 gap-4">
          <p>{{ $t('newPassword') }}: </p>
          <div>
            <vs-input type="password" v-validate="'required'" name="newPassword" ref="newPassword" class="w-full inputx mb-1" placeholder="Password" v-model="newPassword" />
            <span class="text-danger text-sm" v-show="errors.has('newPassword')">{{ errors.first('newPassword') }}</span>
          </div>
          <p>{{ $t('confirmPassword') }}: </p>
          <div>
            <vs-input type="password" v-validate="'required|confirmed:newPassword'" name="confirmation" class="w-full inputx mb-1" placeholder="Confirm Password" v-model="passwordConfirm" />
            <span class="text-danger text-sm" v-show="errors.has('confirmation')">{{ errors.first('confirmation') }}</span>
          </div>

        </div>
        <div class="flex mt-5">
          <vs-button @click="updatePassword()" color="primary" class="mt-5 ml-auto" type="filled">{{ $t('update') }}</vs-button>
        </div>
      </template>
    </vs-popup>
    <div v-if="isLoading" class="flex w-full h-full p-24">
      <img src="@/assets/images/loading1.gif" alt="" width="50" class="mx-auto">
    </div>
    <template v-else>
      <vs-alert color="danger" :active.sync="isActive">
        <span>{{ $t('operator') }} {{ $t('isBlocked') }}.</span>
      </vs-alert>
      <vx-card class="mt-6">
        <vs-row class="mb-3">
          <div class="mr-auto">
            <h3>
              {{ operator.name }}
            </h3>
            <vs-chip :color="getRoleColor(operator.role)">{{ operator.role | capitalize }}</vs-chip>
          </div>
          <vs-button v-if="$acl.check('superadmin')" type="border" color="warning" @click.prevent="changePassword" class="py-3 px-5 mb-4 mr-4 rounded-lg">
            <feather-icon icon="EditIcon" svgClasses="h-4 w-4" />
            {{ $t('changePassword') }}
          </vs-button>
          <template v-if="operator.uuid !== activeUser.uuid">
            <vs-button v-if="$acl.check('superadmin') && operator.isBlocked" type="border" color="primary" @click.prevent="confirmStatusChange" class="py-3 px-5 mb-4 mr-4 rounded-lg">
              <feather-icon icon="UnlockIcon" svgClasses="h-4 w-4" />
              {{ $t('unblock') }}
            </vs-button>
            <vs-button v-else-if="$acl.check('superadmin')" type="border" color="danger" @click.prevent="confirmStatusChange" class="py-3 px-5 mb-4 mr-4 rounded-lg">
              <feather-icon icon="LockIcon" svgClasses="h-4 w-4" />
              {{ $t('block') }}
            </vs-button>
          </template>
          <router-link v-if="$acl.check('superadmin')" :to="{name: 'operatorEdit', params: {id: $route.params.id}}" class="py-3 px-5  mb-4 rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base text-primary border border-solid border-primary">
            <feather-icon icon="EditIcon" svgClasses="h-4 w-4" />
            <span class="ml-2 text-base text-primary">{{ $t('edit') }}</span>
          </router-link>
          <div v-if="$acl.check('superadmin') && operator.isBlocked" class="btn-delete p-3 mb-4 ml-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-danger border border-solid border-danger" @click="confirmDelete">
            <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" />
            <span class="ml-2 text-base">{{ $t('delete') }}</span>
          </div>
        </vs-row>

        <vs-table stripe :data="userProps">
          <template slot="thead">
            <vs-th>{{ $t('property') }}</vs-th>
            <vs-th>{{ $t('value') }}</vs-th>
          </template>
          <template>
            <vs-tr v-for="(prop, idx) in userProps" :key="idx">
              <vs-td>
                <span class="uppercase font-medium">{{ $t(`props.${prop}`) }}</span>
              </vs-td>
              <vs-td v-if="operator[prop] === true || operator[prop] === false">
                <vs-chip :color="operator[prop] ? 'success' : 'danger'">
                  <feather-icon v-if="operator[prop]" icon="CheckIcon" svgClasses="w-5 h-5" />
                  <feather-icon v-else icon="XIcon" svgClasses="w-5 h-5" />
                </vs-chip>
              </vs-td>
              <vs-td v-else>
                {{ operator[prop] }}
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <!-- {{ items }} -->
        <!-- {{ updatedItems }} -->
      </vx-card>
    </template>
  </div>
</template>

<script>
import vSelect from 'vue-select'
export default {
  components: {
    vSelect
  },
  data () {
    return {
      operator: {},
      showModal: false,
      isMounted: false,
      isLoading: true,
      newPassword: '',
      passwordConfirm: ''
    }
  },
  async created () {
    await this.fetchData()
    this.isMounted = true
  },
  computed: {
    isActive () { return this.operator.isBlocked },
    userProps () { return Object.keys(this.operator).filter(el => el !== 'device_token') },
    activeUser () { return this.$store.state.AppActiveUser }
  },
  methods: {
    getRoleColor (role) {
      if (role === 'superadmin')      return 'success'
      if (role === 'admin')           return 'primary'
      if (role === 'operator')        return 'warning'
      if (role === 'stock_operator')  return 'danger'
      return 'primary'
    },
    async fetchData () {
      if (this.$route.params.id === this.activeUser.uuid) {
        await this.$http.get('/get-me').then(response => {
          this.operator = response.data.you
          this.isLoading = false
        }).catch((err) => {
          this.isLoading = false
          this.notify('error', err.message)
        })
      } else {
        await this.$http.get(`/admins/${this.$route.params.id}`).then(response => {
          this.operator = response.data
          this.isLoading = false
        }).catch((err) => {
          this.isLoading = false
          this.notify('error', err.message)
        })
      }
    },
    confirmDelete () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm Delete',
        text: `${this.$t('confirmDelete')} "${this.operator.name}"`,
        accept: this.deleteData,
        acceptText: this.$t('delete'),
        cancelText: this.$t('cancel')
      })
    },
    async deleteData () {
      await this.$http.delete(`/admins/delete/${this.operator.uuid}`).then(() => {
        this.notify('success', `${this.$t('operator')} ${this.$t('notify.sDeleted')}`)
        this.$router.push('/operators')
      }).catch(err => {
        this.notify('error', err.response.data.message)
      })
    },
    changePassword () {
      this.newPassword = ''
      this.passwordConfirm = ''
      this.editingItems = false
      this.showModal = true
    },
    updatePassword () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$http.patch(`/admins/edit/${this.operator.uuid}`, {
            password: this.newPassword
          }).then(() => {
            this.showModal = false
            this.notify('success', `${this.$t('operator')} ${this.$t('password')} ${this.$t('notify.sUpdated')}`)
            this.fetchData()
          }).catch(err => {
            this.notify('error', err.message)
          })
        } else {
          this.notify('error', this.$t('notify.formInvalid'))
        }
      })
    },
    confirmStatusChange () {
      this.$vs.dialog({
        type: 'confirm',
        color: this.operator.isBlocked ? 'primary' : 'danger',
        title: `${this.operator.isBlocked ? this.$t('unblock') : this.$t('block')}`,
        text: `${this.operator.isBlocked ? this.$t('confirmUnblock') : this.$t('confirmBlock')} "${this.operator.username}"`,
        accept: this.changeStatus,
        acceptText: this.$t('submit'),
        cancelText: this.$t('cancel')
      })
    },
    async changeStatus () {
      await this.$http.patch(`/admins/edit/${this.operator.uuid}`, {
        isBlocked: !this.operator.isBlocked
      }).then(() => {
        this.fetchData()
        this.notify('success', `${this.$t('operator')} ${this.$t('status')} ${this.$t('notify.sUpdated')}`)
      }).catch(err => {
        this.notify('error', err.message)
      })
    },
    notify (status, msg) {
      if (status === 'success') {
        this.$vs.notify({
          title: this.$t('notify.success'),
          text: msg,
          iconPack: 'feather',
          icon: 'icon-check-circle',
          color: 'primary'
        })
      } else {
        this.$vs.notify({
          title: this.$t('notify.error'),
          text: msg,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      }
    }
  }
}
</script>
